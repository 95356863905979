import Modal from 'components/global/Modal'
import Resource from 'components/global/Resource'
import SectionTitle from 'components/global/SectionTitle'
import { Col, Row, Section } from 'components/grid'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { AcceleratorBasketQueryEdge, AcceleratorToolkitQueryEdge, extractColorLevel, toolkitProperties } from 'pages/intervention'
import React, { useState } from 'react'

interface Props {
	gradeLevel: 'K-5' | '6-12'
	level: 'elementary' | 'secondary'
	wideReading: AcceleratorBasketQueryEdge
	toolkits: AcceleratorToolkitQueryEdge
}

export default function Accelerator({ gradeLevel, level, wideReading, toolkits }: Props) {
	const toolkitNames =
		level === 'elementary'
			? ['FSTK | 1G', 'FSTK | 2G', 'FSTK | 1B', 'FSTK | 2B', 'FSTK | 1R', 'FSTK | 2R', 'IRLA Toolkit | Wt', 'IRLA Toolkit | Bk']
			: ['Secondary Toolkit | 1R', 'Secondary Toolkit | 2R', 'Secondary Toolkit | Wt', 'Secondary Toolkit | Bk', 'IRLA Toolkit | Or', 'IRLA Toolkit | Pu']

	const [isModalOpen, setIsModalOpen] = useState(false)
	return (
		<Section margin="mt-32" scrollMarginTop="75px" id={level}>
			<Row center>
				<Col>
					<div className="mx-auto mb-10 max-w-3xl text-center">
						<h2 className="mb-2 text-5xl capitalize text-ab-100 md:text-6xl">Ohio Intervention {gradeLevel}</h2>
					</div>
				</Col>
			</Row>
			<SectionTitle
				title={[
					<span key={Math.random()}>
						<span>ARC Accelerator™</span>
						<br />
						<span className="capitalize">{level} Grades</span>
					</span>
				]}
				text={`An intervention program designed to provide targeted instruction and practice for ${level} students not yet reading on grade level.`}
			/>
			<Row center middle className="mb-8">
				<Col width="w-full lg:w-7/12" className="mb-6 lg:mb-0">
					<button onClick={() => setIsModalOpen(true)}>
						{level === 'elementary' ? (
							<StaticImage
								imgClassName="rounded-lg"
								src="../../../../static/images/intervention/daily-lesson-structure-elementary.png"
								alt="daily lesson structure graph"
							/>
						) : (
							<StaticImage
								imgClassName="rounded-lg"
								src="../../../../static/images/intervention/daily-lesson-structure-secondary.png"
								alt="daily lesson structure graph"
							/>
						)}
					</button>
				</Col>
				<Col width="w-full lg:w-5/12">
					<div className="rounded-lg bg-gray-100 p-6">
						<span className="mb-2 block text-2xl font-semibold">Physical and digital resources work together to help educators:</span>
						<ul className="list-checkmark pl-10 text-lg text-slate-600">
							<li className="mb-3">Teach all students high-utility skills/strategies for handling any text.</li>
							<li className="mb-3">Provide explicit, systematic instruction in the specific skills each reader needs.</li>
							<li>Ensure essential reading practice to expand vocabulary and background knowledge.</li>
						</ul>
					</div>
				</Col>
			</Row>
			<Row center middle className="mb-8">
				<Col width="w-1/2 md:w-3/12" className="material-column">
					<div className="flex h-full flex-col">
						<Resource protected title={`ARC Accelerator | ${level.charAt(0).toUpperCase() + level.slice(1)}`} noLink noZoom />
						<div className="mt-auto pt-4">
							<span className="inline-block w-full text-center text-xl font-semibold">Teacher Guide</span>
						</div>
					</div>
				</Col>
				<Col width="w-1/2 md:w-4/12">
					<div className="flex h-full flex-col">
						<div className="flex">
							<Resource title="IRLA" protected noZoom />
							<div className="-ml-28">
								<Resource title="MLL Guide" protected noZoom />
							</div>
						</div>
						<div className="mt-auto pt-4">
							<span className="inline-block w-full text-center text-xl font-semibold">IRLA Framework & Guide to IRLA Coaching with Multilingual Learners</span>
						</div>
					</div>
				</Col>
				<Col width="w-full md:w-5/12">
					<div className="flex h-full flex-col">
						<StaticImage src="../../../static/images/intervention/arc-reads.png" placeholder="blurred" alt="arc accelerator" />
						<div className="mt-auto pt-4">
							<span className="inline-block w-full text-center text-xl font-semibold">SchoolPace & ARC Reads Digital Logbook</span>
						</div>
					</div>
				</Col>
			</Row>

			<Row className="mb-12">
				<div className="flex flex-col px-4">
					<div className="flex flex-wrap justify-center">
						{toolkits.edges
							.sort((a, b) => {
								const orderA = toolkitProperties[a.node.name.slice(-2) as keyof typeof toolkitProperties].order
								const orderB = toolkitProperties[b.node.name.slice(-2) as keyof typeof toolkitProperties].order
								return orderA - orderB
							})
							.map(({ node }) => {
								return (
									<Col key={node.name} width="w-1/2 sm:w-1/3 md:w-1/4" gutter="none" className="mb-6">
										<Resource key={node.name} title={toolkitNames.find((t) => t.includes(node.name.slice(-2)))!} protectedLink>
											<GatsbyImage image={node.images[0].gatsbyImageData} alt={node.name} />
											<span className="mt-2 inline-block text-ab-100">{toolkitNames.find((t) => t.includes(node.name.slice(-2)))}</span>
										</Resource>
									</Col>
								)
							})}
					</div>
					<div className="mt-auto pt-4">
						<span className="inline-block w-full text-center text-xl font-semibold">IRLA Toolkits</span>
					</div>
				</div>
			</Row>
			<Row>
				<div className="flex flex-col px-4">
					<div className="flex flex-wrap justify-center">
						{wideReading.edges
							.sort((a, b) => {
								const colorLevelA = extractColorLevel(a.node.title, level === 'secondary' ? true : false)
								const colorLevelB = extractColorLevel(b.node.title, level === 'secondary' ? true : false)

								const orderA = toolkitProperties[colorLevelA as keyof typeof toolkitProperties].order || 0
								const orderB = toolkitProperties[colorLevelB as keyof typeof toolkitProperties].order || 0
								return orderA - orderB
							})
							.map(({ node }) => {
								return (
									<Col key={node.title} width="w-1/2 sm:w-1/3 md:w-1/6" gutter="none" className="mb-6">
										<GatsbyImage image={node.gatsbyImageData} alt={node.title} />
									</Col>
								)
							})}
					</div>
					<div className="mt-auto pt-4">
						<span className="inline-block w-full text-center text-xl font-semibold">Hook Book Library</span>
					</div>
				</div>
			</Row>
			<Modal wide title="Daily Lesson Structure" isOpen={isModalOpen} handleClose={() => setIsModalOpen(false)}>
				{level === 'elementary' ? (
					<StaticImage imgClassName="rounded-lg" src="../../../static/images/intervention/daily-lesson-structure-elementary.png" alt="daily lesson structure graph" />
				) : (
					<StaticImage imgClassName="rounded-lg" src="../../../static/images/intervention/daily-lesson-structure-secondary.png" alt="daily lesson structure graph" />
				)}
			</Modal>
		</Section>
	)
}
